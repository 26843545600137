import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueGtag from 'vue-gtag';
import VueGtm from '@gtm-support/vue2-gtm';
import vuetify from '@/plugins/vuetify';
import en from './languages/en.json';
import es from './languages/es.json';
import br from './languages/br.json';
import FlagIcon from 'vue-flag-icon';
import VueI18n from 'vue-i18n';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'vue2-timepicker/dist/VueTimepicker.css';
import '@mdi/font/css/materialdesignicons.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vue2Editor from 'vue2-editor';

require('@/store/subscriber');

const options = {
  title: 'Oh no!',
  confirmButtonColor: '#E35353',
  customClass: {
    confirmButton: 'btn btn-block'
  },
  imageWidth: 105,
  imageHeight: 105,
  imageAlt: 'Logo PROSK'
};
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_ID }
});
Vue.use(FlagIcon);
Vue.use(VueI18n);
Vue.use(VueSweetalert2, options);
Vue.use(Vue2Editor);

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  defer: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false
});

const i18n = new VueI18n({
  locale: process.env.VUE_APP_LOCALE,
  fallbackLocale: [ 'es' ],
  messages: {
    en: en,
    es: es,
    br: br
  }
});

Vue.config.productionTip = false;

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: (h) => h(App)
  }).$mount('#app');
});
